const { $ } = window;
/**
 * messageUtil 객체 생성
 * @requires window.jQuery
 */

/**
 * messageUtil.alert({옵션}, ok 함수);
 * messageUtil.confirm({옵션}, ok 함수, cancel 함수);
 * messageUtil.toast({옵션});
 *
 * option
 * target: 레이아웃
 * okBtn: ok버튼 유무(alert: o confirm: o toast: X)
 * cancelBtn: cancel 버튼 유무(alert: X confirm: o toast: X)
 * okText: ok버튼 텍스트(alert: o confirm: o toast: X)
 * cancelText: cancel버튼 텍스트(alert: x confirm: o toast: X)
 * message: 노출 메시지
 * msgType: messageUtil에 정의되어있는 message를 노출할 경우 입력
 * timeout: toast 노출 시간(alert: x confirm: x toast: X)
 */

const popup = {
  modal: '.message_layer',
  toast: '.layer_toast',
};

const alertOptions = {
  target: popup.modal,
  okBtn: true,
  okText: '확인',
  message: '',
};

const confirmOptions = {
  target: popup.modal,
  okBtn: true,
  cancelBtn: true,
  okText: '네',
  cancelText: '아니오',
  message: '',
};

const toastOptions = {
  target: popup.toast,
  message: '',
  timeout: 2000,
};

const message = {
  bookmark: '북마크 되었습니다. 내 보관함에서 확인하세요.',
  copyLink: '링크가 복사되었습니다.',
  livelogin: '로그인이 필요한 서비스입니다. 로그인페이지로 이동하시겠습니까?',
  subsLogin: '로그인이 필요한 서비스입니다. 로그인페이지로 이동하시겠습니까?',
  bookmarkLogin: '로그인이 필요한 서비스입니다. 로그인페이지로 이동하시겠습니까?',
};

export class MessageUtil {
  set(options) {
    const $target = $(options.target);
    $target.find('.hide').removeClass('hide');

    let msg = options.message;

    if (typeof options.msgType !== 'undefined') {
      msg = message[options.msgType];
    }

    if ($target.hasClass('layer_toast')) {
      $target.html(msg);
    } else {
      $target.find('.layer_body p').html(msg);
    }

    if (options.okBtn) {
      $target.find('.btn_ok').text(options.okText);
    } else {
      $target.find('.btn_ok').addClass('hide');
    }

    if (options.cancelBtn) {
      $target.find('.btn_cancel').text(options.cancelText);
    } else {
      $target.find('.btn_cancel').addClass('hide');
    }
  }

  alert(customOptions, ok) {
    const options = $.extend({}, alertOptions, customOptions);

    this.set(options);
    this.openLayerPopup(options, ok);
  }

  confirm(customOptions, ok, cancel) {
    const options = $.extend({}, confirmOptions, customOptions);
    this.set(options);
    this.openLayerPopup(options, ok, cancel);
  }

  toast(customOptions) {
    const options = $.extend({}, toastOptions, customOptions);
    this.set(options);
    this.openToast(options);
  }

  openLayerPopup(options, ok, cancel) {
    const self = this;
    const $target = $(options.target);
    $target.removeClass('hide').addClass('show');

    $('html, body').addClass('overflow_hidden');

    $target
      .find('.btn_ok')
      .off()
      .on('click', function (e) {
        e.stopPropagation();
        e.preventDefault();
        if (ok) {
          ok(e);
        }
        self.closeLayerPopup($target);
      });

    $target
      .find('.btn_cancel')
      .off()
      .on('click', function (e) {
        e.stopPropagation();
        e.preventDefault();
        if (cancel) {
          cancel(e);
        }
        self.closeLayerPopup($target);
      });

    $target
      .find('.btn_close')
      .off()
      .on('click', function (e) {
        e.stopPropagation();
        e.preventDefault();
        if (cancel) {
          cancel(e);
        }
        self.closeLayerPopup($target);
      });
  }

  closeLayerPopup($target) {
    $target.removeClass('show').addClass('hide');
    $('html, body').removeClass('overflow_hidden');
  }

  openToast(options) {
    const $target = $(options.target);
    $target.fadeIn();

    setTimeout(function () {
      $target.fadeOut();
    }, options.timeout);
  }
}
